<template>
    <div class="page1">
        <EForm :formColumns="formColumns"
               :rowSize="4"
               :optionsBtn="true"
               :actionBtn="false"
               :formData="searchForm"
               ref="form"
               @onSearch="onSearch"
               :searchFlag="true"
               :exportShow="false"
               @getChange="changeSelect">
        </EForm>
        <div class="mt20 fs15">
            符合条件记录：共{{total.feeNum}}单，{{total.totalAmount}}元
        </div>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size">
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" v-if="scope.row.confirmStatus==1||(scope.row.confirmStatus==3&&scope.row.status==0)" @click="goTo('CostEdit',{id:scope.row.orderId})">
                          修改
                        </EButton>
                        <EButton type="text" v-if="scope.row.confirmStatus==1||(scope.row.confirmStatus==3&&scope.row.status==0)" @click="remove(scope.row.orderId)">
                          删除
                        </EButton>
                        
                        <EButton type="text" @click="getListByOrderId(scope.row.orderId)">
                          明细
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog
                :dialogVisible="dialogVisible"
                title="明细"
                @handleClose="dialogVisible=false"
                width="50%"
                @handleClick="dialogVisible=false">
            <ETable :tableTitle="tableTitle2"
                    :tableData="tableData2"
                    :needPagination="false"
            >
            </ETable>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import { identity_type, status } from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'shopInfo',
    mixins:[otherMixin],
    data () {
      return {
        tableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum',
            width:150
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName',
            width:150
          },
          {
            label: '费用名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '费用笔数',
            prop: 'feeNum'
          },
          {
            label: '录入人',
            prop: 'createUserCn'
          },
          {
            label: '录入时间',
            prop: 'createTime'
          },
          {
            label: '财务确认人',
            prop: 'confirmPersonName'
          },
          {
            label: '财务确认',
            prop: 'confirmStatusCn'
          },
          {
            label: '收费状态',
            prop: 'statusCn'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '财务确认',
            type: 'select',
            property: 'confirmStatus',
            show: true,
            options:[
              {
                label:'全部',
                value:''
              },
              {
                label:'未确认',
                value:1
              },
              {
                label:'已确认',
                value:2
              },
              {
                label:'不确认',
                value:3
              },
              {
                label:'已退回',
                value:4
              },
            ]
          },
          {
            title: '录入开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '财务退回开始时间',
            type: 'datePicker',
            property: 'confirmTimeStart',
            show: true
          },
          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '收费状态',
            type: 'select',
            property: 'status',
            show: true,
            options: [
              {
                label:'全部',
                value:''
              },
              {
                label:'未收费',
                value:0
              },
              {
                label:'已收费',
                value:1
              },
              {
                label:'已取消',
                value:2
              },
            ]
          },
          {
            title: '录入截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
          {
            title: '财务退回截止时间',
            type: 'datePicker',
            property: 'confirmTimeEnd',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '收费人',
            type: 'text',
            property: 'chargePersonName',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          confirmStatus: null,
          createTimeStart: null,
          confirmTimeStart: null,
          areaId: null,
          status: null,
          createTimeEnd: null,
          confirmTimeEnd: null,
          placeId: null,
          chargePersonName: null,
          current: 1,
          size: 10,
        },
        count: null,
        total: {
          feeNum:0,
          totalAmount:0
        },
        options: [],
        form: {},
        areaOptions: [],
        locationOptions: [],
        dialogVisible: false,//弹窗信息
        tableTitle2:[
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
          },
          {
            label: '收费金额',
            prop: 'payAmount',
          },
        ],
        tableData2:[]
      }
    },
    mounted(){
      console.log(this.$el.clientWidth)
    },
    watch: {},
    created () {
      this.getData()
      this.getAreaLabel()
    },
    components: { ETable, EDialog, EButton, EForm },
    beforeMount () {

    },
    methods: {
      changeSelect(prop){
        if(prop=='areaId'){
          this.getPlaceLabel()
        }
      },
      async getListByOrderId(id){
        let res = await Http.getListByOrderId({id})
        if (res.code == 200) {
          this.tableData2=res.data
          this.dialogVisible=true
        }
      },
      change(id, status) {
        let msg = ''
        if (status == 1) {
          msg = '禁用'
        } else {
          msg = '启用'
        }
        this.$messageBox.confirm('确定' + msg + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.changeStatus(id, status==1?0:1)
        }).catch(res => {

        });
      },
      saveData (boothInformationId) {
        this.$refs['form'].validate((valid, object) => {
          if (valid) {
            this.boothInfoSubmit(boothInformationId)
          } else {
            return false;
          }
        })
      },
      onSearch () {
        this.searchForm.current = 1
        this.getData()
      },
      changePage (current) {
        this.searchForm.current = current
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      cancelDialog () {
        this.dialogVisible = false
        this.dialogForm = {
          boothInformationId: '',
          boothCode: '',
          areas: '',
          areaId: '',
          boothUseId: '',
          placeId: '',
          boothTypeId: '',
          remark: '',
        }
        this.$refs.form.resetFields();
      },
      showDialog (row) {
        this.dialogForm = {
          boothInformationId: row.boothInformationId,
          boothCode: row.boothCode,
          areas: row.areas,
          areaId: row.areaId,
          boothUseId: row.boothUseId,
          placeId: row.placeId,
          boothTypeId: row.boothTypeId,
          remark: row.remark,
        }
        this.dialogVisible = true
      },
      async getData () {
        let res = await Http.expenseEntryList(this.searchForm)
        if (res.code == 200) {
          let data=res.data
          this.tableData = data.page.records
          this.count = data.page.total
          this.total={
            feeNum: data.feeNum,
            totalAmount: data.totalAmount,
          }

        }
      },
      //新增或者修改请求
      async boothInfoSubmit (boothInformationId) {
        let params = {
          boothCode: this.dialogForm.boothCode,
          areas: this.dialogForm.areas,
          areaId: this.dialogForm.areaId,
          boothUseId: this.dialogForm.boothUseId,
          placeId: this.dialogForm.placeId,
          boothTypeId: this.dialogForm.boothTypeId,
          remark: this.dialogForm.remark,
        }
        if (boothInformationId) {
          params.boothInformationId = boothInformationId
        }
        let res = await Http.boothInfoSubmit(params)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.dialogVisible = false
          this.cancelDialog()
          this.getData()
        }
      },
      remove (id) {
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.expenseEntryDelete(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      async expenseEntryDelete (id) {
        let res = await Http.expenseEntryDelete({ id })
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      //获取位置下拉
      async getPlaceLabel () {
        let res = await Http.getPlaceLabel({
          areaId:this.searchForm.areaId,
          status:null
        })
        if (res.code == 200) {
          this.formColumns[8].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel () {
        let res = await Http.getAreaLabel({
          status:null
        })
        if (res.code == 200) {
          this.formColumns[4].options = res.data
          this.areaOptions = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
